import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { receiveTickets } from '@ttstr/actions';
import { Container, LoadingSpinner, Masonry, useIntl } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

const TicketListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, tickets } = useShallowEqualSelector(mapStateToProps);
  const { receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveTickets();
  }, [language]);

  return (
    <article className="ticket-listing">
      <Container className="list-page">
        <Helmet>
          <title>{t(`TICKETLISTING.TITLE`)}</title>
          <body className="ticket-listing-page" />
        </Helmet>

        {/* <h1 className="text-center mb-4">{t(`TICKETLISTING.TITLE`)}</h1> */}

        <div className="text-center">
          <img
            src={require('./assets/images/header-tickets.png')}
            alt="Tourbanner der Jochen Distelmeyer Live 2023 - Gefühlte Wahrheiten Solo Acoustic Tour"
            className="img-fluid my-3"
          />
        </div>

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.TICKETS`)} />
        ) : tickets.length === 0 ? ( // if no tickets
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show tickets
          <Masonry
            id="ticket-listing"
            products={tickets}
            type="list"
            filters="off"
            showTitle
            showCity
            showLocation
            showTags
          />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { tickets, loading } = state.Tickets;
  return {
    tickets,
    loading,
  };
};

const mapDispatchToProps = {
  receiveTickets,
};

export default React.memo(TicketListing);
